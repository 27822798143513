{
  "name": "esri-leaflet-heatmap",
  "description": "Esri Leaflet plugin for visualizing Feature Layers as heatmaps with L.heat.",
  "version": "2.0.0",
  "author": "Patrick Arlt <parlt@esri.com> (http://patrickarlt.com)",
  "contributors": [
    "Patrick Arlt <parlt@esri.com> (http://patrickarlt.com)",
    "John Gravois <jgravois@esri.com> (http://johngravois.com)"
  ],
  "dependencies": {
    "esri-leaflet": "^2.0.0",
    "leaflet": "^1.0.0-rc.3",
    "leaflet.heat": "^0.2.0"
  },
  "devDependencies": {
    "chai": "2.3.0",
    "gh-release": "^2.0.0",
    "http-server": "^0.9.0",
    "isparta": "^3.0.3",
    "istanbul": "^0.4.2",
    "karma": "^0.12.24",
    "karma-chai-sinon": "^0.1.3",
    "karma-coverage": "^0.5.3",
    "karma-mocha": "^0.1.0",
    "karma-mocha-reporter": "^0.2.5",
    "karma-phantomjs-launcher": "^0.1.4",
    "karma-sourcemap-loader": "^0.3.5",
    "mkdirp": "^0.5.1",
    "node-sass": "^3.2.0",
    "phantomjs": "^1.9.17",
    "rollup": "^0.25.4",
    "rollup-plugin-json": "^2.0.0",
    "rollup-plugin-node-resolve": "^1.4.0",
    "rollup-plugin-uglify": "^0.3.1",
    "semistandard": "^7.0.2",
    "sinon": "^1.11.1",
    "sinon-chai": "2.7.0",
    "snazzy": "^2.0.1",
    "watch": "^0.17.1"
  },
  "homepage": "https://github.com/Esri/esri-leaflet-heatmap",
  "jsnext:main": "src/HeatmapFeatureLayer.js",
  "jspm": {
    "registry": "npm",
    "format": "es6",
    "main": "src/HeatmapFeatureLayer.js"
  },
  "license": "Apache-2.0",
  "main": "dist/esri-leaflet-heatmap-debug.js",
  "readmeFilename": "README.md",
  "repository": {
    "type": "git",
    "url": "git+https://github.com:Esri/esri-leaflet-heatmap.git"
  },
  "scripts": {
    "prebuild": "mkdirp dist",
    "build": "rollup -c profiles/debug.js & rollup -c profiles/production.js",
    "lint": "semistandard src/*.js && semistandard spec/*.js | snazzy",
    "prepublish": "npm run build",
    "pretest": "npm run build",
    "release": "./scripts/release.sh",
    "start": "watch 'npm run build' src & http-server -p 6789 -c-1 -o",
    "test": "npm run lint && karma start"
  }
}
